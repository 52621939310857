:root {
  --endodental: #01d2c4;
  --secondary: #6c757d;
}

body {
  font-family: "Roboto";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

header {
  color: var(--secondary);
}

header.top-menu {
  height: 64px;
  font-size: 24px;
  padding: 4px 12px;
  position: relative;
  justify-content: space-between;
  box-shadow: 0px 12px 4px -6px #aaaaaa;
}

header.top-menu .app-icon {
  width: 56px;
  height: 56px;
}

header.top-menu .header-title {
  width: 100%;
  text-align: right;
  line-height: 28px;
  font-weight: bold;
  margin-bottom: 0;
}
